export { matchers } from './client-matchers.js';

export const nodes = [() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64')];

export const server_loads = [];

export const dictionary = {
	"/": [11],
	"/ai": [12,[2]],
	"/ai/copilot": [15,[2]],
	"/ai/[projectId]": [13,[2,3]],
	"/ai/[projectId]/prompts": [14,[2,3]],
	"/calendar": [16],
	"/clients": [17],
	"/clients/list": [29],
	"/clients/[clientId]": [18,[4]],
	"/clients/[clientId]/bookings": [19,[4]],
	"/clients/[clientId]/healthplan": [20,[4]],
	"/clients/[clientId]/message": [21,[4,5]],
	"/clients/[clientId]/message/conversation": [23,[4,5]],
	"/clients/[clientId]/message/[conversationId]": [22,[4,5]],
	"/clients/[clientId]/notes": [24,[4]],
	"/clients/[clientId]/overview": [25,[4]],
	"/clients/[clientId]/profile": [26,[4]],
	"/clients/[clientId]/testresults": [27,[4]],
	"/clients/[clientId]/testresults/[reportId]": [28,[4]],
	"/companies": [30],
	"/companies/[companyId]/benefits": [31,[6]],
	"/companies/[companyId]/clients": [32,[6]],
	"/companies/[companyId]/message": [33,[6,7]],
	"/companies/[companyId]/message/[conversationId]": [34,[6,7]],
	"/companies/[companyId]/roles": [35,[6]],
	"/companies/[companyId]/settings": [36,[6]],
	"/companies/[companyId]/tags": [37,[6]],
	"/companies/[companyId]/testphases": [38,[6]],
	"/companies/[companyId]/testphases/[testPhaseId]": [39,[6]],
	"/company-messages": [40],
	"/feature-toggle": [41],
	"/feature-toggle/new": [43],
	"/feature-toggle/[featureId]": [42],
	"/feedbacks": [44],
	"/kpis": [45],
	"/login": [46],
	"/message-logs": [47],
	"/messages": [48,[8]],
	"/messages/all": [49,[8]],
	"/messages/monitored": [50,[8]],
	"/okr": [51],
	"/profile": [52],
	"/register": [53],
	"/reports/merge": [58,[9]],
	"/reports/[reportId=integer]": [54,[10]],
	"/reports/[reportId=integer]/reportinformation": [55,[10]],
	"/reports/[reportId=integer]/survey": [56,[10]],
	"/reports/[reportType]": [57,[9]],
	"/reset-password": [59],
	"/settings": [60],
	"/stats": [61],
	"/styling-test": [62],
	"/unauthorized": [63],
	"/users": [64]
};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};